import React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import Icon from "../../images/assets/logoXLZwart.svg";

// varianten van de framer drawer

const container = {
  closed: {
    opacity: 0,
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
  open: {
    opacity: 1,

    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
};

const backdrop = {
  visible: { opacity: 1, transition: { delay: 0.5, duration: 0.5 } },
  hidden: { opacity: 0 },
  exit: { opacity: 0 },
};

const item = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const menuDrawerVariants = {
  open: {
    top: 0,
  },
  closed: {
    top: "-100vh",
    transition: {
      duration: 0.4,
    },
  },
};

const Navigationbar = () => {
  const [toggler, setToggler] = useState(false);

  //toggleHandler verandert de toggle state op de klik van de hamburger-X button

  const toggleHandler = () => {
    setToggler(!toggler);
  };

  // strokedrawerhanlder returned de kleur die verandert bij de drawer

  const strokeDrawerHandler = () => {
    if (toggler) {
      return "#F0F0F0";
    } else {
      return "#212121";
    }
  };

  return (
    <div className="font-inter">
      {/* desktop navigatiebalk */}
      <div className="hidden xl:flex flex-row-reverse justify-between  w-full pt-12 text-black-light font-semibold relative">
        <div className=" absolute left-0 2xl:left-4 -top-4">
          <Link to="/">
            <Icon></Icon>
          </Link>
        </div>
        <div className="mr-12 space-x-12 ">
          <Link to="/">Over ons</Link>
          <Link to="/team">Team </Link>
          <Link to="/praktijkgebieden">Praktijkgebieden</Link>

          <Link to="/contact">Contact</Link>
        </div>
      </div>

      {/* mobile navigatiebalk */}

      <div className="  relative flex xl:hidden flex-row justify-between w-full  pt-12">
        <div className="absolute left-4  -top-7 ">
          <Link className="" to="/">
            <Icon></Icon>
          </Link>
        </div>

        {/* hamburger */}
        <div className=" mr-6 sm:mr-12 mt-6  absolute right-0 sm:right-0 top-0 h-full flex flex-col justify-center  z-50">
          <motion.button
            className="-mr-1  md:mt-3 focus:outline-none   z-50"
            onClick={toggleHandler}
            initial={false}
            animate={toggler ? "open" : "closed"}
          >
            <svg width="35" height="30" viewBox="0 0 21 20" className=" ">
              <motion.path
                fill="#212121"
                stroke={strokeDrawerHandler()}
                className={`z-50 stroke-current ${strokeDrawerHandler()}`}
                strokeWidth="2"
                // strokeLinecap="round"
                // bovenste lijn
                variants={{
                  closed: {
                    d: "M 0.00,13.25 C 0.00,13.25 18.00,13.25 18.00,13.25",
                  },
                  open: {
                    d: "M 1.29,18.97 C 1.29,18.97 18.97,1.29 18.97,1.29",
                  },
                }}
              />
              <motion.path
                fill="#212121"
                stroke={strokeDrawerHandler()}
                className={`z-50 stroke-current ${strokeDrawerHandler()}`}
                strokeWidth="2"
                //midden
                variants={{
                  closed: {
                    opacity: 1,
                    d: "M 6.00,7.42 C 6.00,7.42 18.00,7.42 18.00,7.42",
                  },
                  open: { opacity: 0 },
                }}
                transition={{ duration: 0.2 }}
              />
              <motion.path
                fill="#212121"
                stroke={strokeDrawerHandler()}
                className={`z-50 stroke-current ${strokeDrawerHandler()}`}
                strokeWidth="2"
                variants={{
                  closed: {
                    d: "M 0.00,1.00 C 0.00,1.00 18.00,1.00 18.00,1.00",
                  },
                  open: {
                    d: "M 1.71,1.29 C 1.71,1.29 19.38,18.97 19.38,18.97",
                  },
                }}
              />
            </svg>
          </motion.button>
        </div>
      </div>

      {/* start van de drawer */}
      <motion.div
        initial={false}
        variants={menuDrawerVariants}
        // animate={isOpen ? "open" : "closed"}
        animate={toggler ? "open" : "closed"}
        transition={{ duration: 0.3 }}
        className={`${
          toggler ? "fixed z-40" : "absolute z-10"
        } h-screen bg-black  w-full`}
      >
        <motion.div
          className="absolute flex flex-row justify-start top-0  mt-16 lg:mt-14 px-6 md:px-12   "
          variants={backdrop}
          initial={false}
          animate={toggler ? "visible" : "hidden"}
        >
          <div className=" self-start h-10 lg:h-20 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 160.57 43.84"
              className="h-full"
            >
              {/* Logo van CVT moet hier  */}
            </svg>
          </div>
        </motion.div>
        <motion.ul
          initial={false}
          variants={container}
          animate={toggler ? "open" : "closed"}
          className="flex h-full flex-col text-3xl sm:text-5xl font-semibold text-gray dark:text-gray-50 justify-center "
        >
          <motion.li
            variants={item}
            className="m-6  md:m-8 cursor-pointer text-4xl   "
          >
            <Link href="/">
              <div className="hover:scale-105 duration-200 transform hover:translate-x-3">
                Over Ons
              </div>
            </Link>
          </motion.li>
          <motion.li
            variants={item}
            className="m-6 md:m-8 cursor-pointer text-4xl  "
          >
            <Link href="/team">
              <div className="hover:scale-105 duration-200 transform hover:translate-x-3">
                Team
              </div>
            </Link>
          </motion.li>
          <motion.li
            variants={item}
            className="m-6 md:m-8 cursor-pointer text-4xl  "
          >
            <Link href="/praktijkgebieden">
              <div className="hover:scale-105 duration-200 transform hover:translate-x-3">
                Praktijkgebieden
              </div>
            </Link>
          </motion.li>
          <motion.li
            variants={item}
            className="m-6 md:m-8 cursor-pointer text-4xl  "
          >
            <Link href="/contact">
              <div className="hover:scale-105 duration-200 transform hover:translate-x-3 ">
                Contact
              </div>
            </Link>
          </motion.li>
        </motion.ul>
      </motion.div>
    </div>
  );
};

export default Navigationbar;
