import React from "react";
import { Link } from "gatsby";

const footer = () => {
  return (
    <div className=" bg-gray-white text-black font-inter mt-40 cursor-default">
      <div className="grid grid-cols-12">
        <div className="col-span-12 lg:col-span-6">
          <div className=" text-3xl sm:text-5xl ml-12 lg:ml-16 font-semibold">
            CVT advocaten
          </div>
          <div className="text-xl sm:text-2xl text-light ml-12 lg:ml-16">
            #OmdatUerRechtOpHebt
          </div>
        </div>

        <div className="col-span-12 lg:col-span-2 ml-12 lg:ml-0 mt-8 lg:mt-0 xl:ml-16">
          <div className="text-lg font-inter text-gray-light font-light">
            Locaties
          </div>
          <div className="text-lg lg:text-base xl:text-lg font-semibold  text-black">
            Kantoor Aalst
          </div>
          <div className="text-lg lg:text-sm xl:text-base font-extralight text-black">
            Keppestraat 119-121
          </div>
          <div className="text-lg lg:text-sm xl:text-base font-extralight text-black">
            9320 Aalst
          </div>
          <div className="text-lg lg:text-sm xl:text-lg font-semibold  text-black">
            Kantoor Opwijk
          </div>
          <div className="text-lg lg:text-sm xl:text-base font-extralight text-black">
            Schoolstraat 27
          </div>
          <div className="text-lg lg:text-sm xl:text-base font-extralight text-black">
            1745 Opwijk
          </div>
        </div>
        <div className="col-span-12 lg:col-span-2 ml-12 lg:ml-0 mt-8 lg:mt-0 xl:ml-16">
          <div className="text-lg font-cg text-gray-light font-light">
            Contact
          </div>
          <div className="text-lg font-extralight text-black lg:text-sm xl:text-base">
            <span className="font-semibold">Aalst</span> 053 22 90 86
          </div>
          <div className="text-lg font-extralight text-black lg:text-sm xl:text-base">
            <span className="font-semibold">Opwijk</span> 052 52 73 91
          </div>
          <div className="text-lg font-extralight text-black lg:text-sm xl:text-base">
            info@cvt-advocaten.be <br /> <br />
          </div>
          <div className="text-lg font-extralight text-black lg:text-sm xl:text-base">
            KBO 0837969142
          </div>
          <div className="text-lg font-extralight text-black lg:text-sm xl:text-base">
            VOF Callebaut & Van Thienen Advocaten
          </div>
        </div>
        <div className="col-span-12 lg:col-span-2 ml-12 lg:ml-0 mt-8 lg:mt-0 xl:ml-16">
          <div className="text-lg font-cg text-gray-light font-light">
            Navigatie
          </div>
          <div className="text-lg lg:text-sm xl:text-base font-extralight text-black">
            <Link to="/">Over ons</Link>
          </div>
          <div className="text-lg lg:text-sm xl:text-base font-extralight text-black">
            <Link to="/team">Team </Link>
          </div>
          <div className="text-lg lg:text-sm xl:text-base font-extralight text-black">
            <Link to="/praktijkgebieden">Praktijkgebieden</Link>
          </div>
          <div className="text-lg lg:text-sm xl:text-base font-extralight text-black">
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6 mt-12">
          <div className="h-24 border-t-2 border-black-light xl:mr-16 lg:text-left xl:text-right ml-0 lg:ml-16 xl:ml-0">
            <div className="ml-12 lg:ml-0">Privacybeleid</div>
          </div>
        </div>
        <div className="hidden lg:block col-span-0 lg:col-span-6 xl:ml-16">
          <div className="h-24 border-t-2 border-black-light lg:text-right xl:text-left mt-12 lg:mr-16 xl:mr-0">
            Werken bij CVT advocaten
          </div>
        </div>
      </div>
    </div>
  );
};

export default footer;
